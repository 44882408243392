import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import moment from "moment"
// layout
import Layout from "../layout/index"
// components
import MetricCard from "./MetricCard"
import Building from "./Building"
// assets
// import houseIcon from "../../../public/house.png"
// import m2Icon from "../../../public/m2.png"
// import valueIcon from "../../../public/value.png"
// import keyIcon from "../../../public/key.png"
// import rentIcon from "../../../public/rent.png"
// import distributionIcon from "../../../public/distribution.png"
// services
import { getPeriodValues, getBuildings } from "../../services/buildings"
import currency from "currency.js"
import { getLastValues } from "../../services/trackRecord"
import { getBuildingUnits } from "../../services/units"
import { getBuildingTokens } from "../../services/tokens"

const Home = (noLayout) => {
  const user = useSelector((state) => state.user)
  const [buildings, setBuildings] = useState([])
  const [buildingsDetails, setBuildingsDetails] = useState([])

  useEffect(() => {
    const getAllBuildings = async () => {
      const buildingsValue = await getPeriodValues(user.token, "2024-08-30") //The last month
      let buildingsDetails = await getBuildings(user.token)
      buildingsDetails = buildingsDetails.filter(
        (building) => building.name !== "EVA"
      )
      setBuildings(buildingsValue)
      setBuildingsDetails(buildingsDetails)
    }

    getAllBuildings()
  }, [])

  const getDaysToUpdate = () => {
    const date = moment()
    const day = date.date()
    let changeDate
    if (day >= 10) {
      changeDate = moment().date(10).add(1, "months")
    } else {
      changeDate = moment().date(10)
    }
    const diff = changeDate.diff(date, "days")
    return diff
  }

  const getAveragePrice = () => {
    const total = buildings.reduce((totalValue, building) => {
      return (totalValue += Number(building.value_a))
    }, 0)
    return (total / 6).toFixed(0)
  }

  const getAverageOccupancyAndRent = () => {
    const buildingsOperating = buildings.filter(
      (item) => item.building_phase_status === "OPERATING"
    )
    let occupancy = buildingsOperating.reduce((totalValue, building) => {
      return (totalValue += Number(building.occupancy) * 100)
    }, 0)
    occupancy = (occupancy / buildingsOperating.length).toFixed(0)

    let rent = buildingsOperating.reduce((totalValue, building) => {
      return (totalValue += Number(building.noi_a))
    }, 0)
    rent = (rent / buildingsOperating.length).toFixed(0)

    return {
      occupancy,
      rent
    }
  }

  const getTotalBuildingsValue = () => {
    const getTokenPrice = (buildingId) => {
      let values = buildings.find(
        (building) => building.building_phase_id === buildingId
      )
      return values?.value_a
    }

    const tokensByBuilding = buildingsDetails.map((building) => {
      return {
        phaseId: building.phase_id,
        tokens: Number(building.a_tokens) + Number(building.b_tokens),
        tokenPrice: getTokenPrice(building?.phase_id),
        totalValue:
          (Number(building.a_tokens) + Number(building.b_tokens)) *
          getTokenPrice(building.phase_id)
      }
    })

    const sumTotalValue = tokensByBuilding.reduce((total, building) => {
      return (total += Number(building.totalValue))
    }, 0)
    return sumTotalValue
  }

  const home = (
    <Container>
      <section className="first-section">
        <div className="container-video">
          <h1>¡Bienvenido a urvita, {user && user?.report?.userName}!</h1>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/cbw9lBxGp5A"
              title="YouTube video urvita"
            ></iframe>
          </div>
        </div>

        <div className="container-buttons">
          <Link to="/simulador">
            <button>Comenzar ahora</button>
          </Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://form.typeform.com/to/FuE131Wu?utm_source=app_urvita&utm_medium=boton_guia&nombre=${user?.report?.userName} ${user?.report?.userLastName}&correo=${user?.report?.userEmail}&id_usuario=${user?.id}`}
          >
            <button className="white">Ver guía</button>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://my.demio.com/ref/QtfxZT0DusLRhb9G?utm_source=AppUrvita`}
          >
            <button className="white">Ver webinar</button>
          </a>
        </div>

        <div className="container-metrics">
          <h3>Métricas</h3>
          <div className="grid-metrics">
            <MetricCard
              icon="/house.png"
              number="65"
              description="Departamentos."
            />
            <MetricCard
              icon="/m2.png"
              number={currency(getAveragePrice(), { precision: 0 }).format()}
              description="Precio por M2."
            />
            <MetricCard
              icon="/value.png"
              number={currency(getTotalBuildingsValue(), {
                precision: 0
              }).format()}
              description="Valor de las propiedades."
            />
            <MetricCard
              icon="/key.png"
              number={`${getAverageOccupancyAndRent().occupancy}%`}
              description="Ocupación."
            />
            <MetricCard
              icon="/rent.png"
              number={currency(getAverageOccupancyAndRent().rent, {
                precision: 0
              }).format()}
              description="Renta promedio por M2."
            />
            <MetricCard
              icon="/distribution.png"
              number="$6.075.000"
              description="Distribución del ultimo año."
            />
          </div>
        </div>
      </section>

      <section className="second-section">
        <div className="container-info">
          <p>
            EI precio del token se actualiza cada mes. Quiere decir que tu poder
            de comprar va disminuyendo. entre más rápido compres evitas el
            devaluó de tu dinero.
          </p>
          <div className="bar">
            <div
              className="progress"
              style={{ width: `${100 - getDaysToUpdate() * 3.3}%` }}
            ></div>
          </div>
          <p className="right">
            Faltan {getDaysToUpdate()} días para el cambio de precio
          </p>
        </div>
        <div className="container-buildings">
          {buildings.map((building) => (
            <Link
              key={building.id}
              to={`/edificios/${building.building_name.toLowerCase()}`}
            >
              <Building
                building={building}
                buildingsDetails={buildingsDetails}
              />
            </Link>
          ))}
        </div>
      </section>
    </Container>
  )

  return (
    <>
      <Layout dashboard lightLogo>
        {home}
      </Layout>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-top: 4rem;

  .first-section {
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    gap: 3rem;
  }

  .container-video {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
      font-size: 2rem;
      font-weight: 300;
      font-family: "calibre";
    }

    .video {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    /* Then style the iframe to fit in the container div with full height and width */
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  .container-buttons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;

    button {
      width: 11rem;
      padding: 13px 0px;
      text-transform: uppercase;
      background-color: #435b49;
      color: white;
      border: none;
      border-radius: 10px;
      font-family: "calibre";
      font-size: 1.1rem;
      cursor: pointer;
    }

    button:hover {
      background-color: #546b5a;
    }

    .white {
      background-color: transparent;
      border: 1px solid #435b49;
      color: #435b49;
    }

    .white:hover {
      background-color: white;
      text-decoration: underline;
    }
  }

  .container-metrics {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h3 {
      font-family: "calibre";
      font-weight: 400;
      font-size: 2rem;
    }

    .grid-metrics {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .second-section {
    background-color: white;
    padding: 2rem 10px 4rem 10px;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .container-info {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      /* width: 800px; */
      /* margin: 0 auto; */
    }

    p {
      font-size: 18px;
      margin: 0;
    }

    .bar {
      height: 20px;
      background-color: #fce1c6;
      border-radius: 20px;
      overflow: hidden;

      .progress {
        width: 80%;
        height: 100%;
        background-color: #f79f4d;
      }
    }

    .right {
      text-align: right;
      font-size: 1.4rem;
      font-weight: 500;
      color: #435b49;
    }
  }

  .container-buildings {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
  }

  @media (min-width: 768px) {
    .first-section {
      width: 600px;
      margin: 0 auto;
    }

    .second-section {
      .container-info {
        width: 600px;
        margin: 0 auto;
      }
    }
  }

  @media (min-width: 1024px) {
    .first-section {
      width: 800px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
    .container-video {
      .video {
        width: 100%;
        display: block;
      }
    }

    .container-metrics {
      h3 {
        font-size: 1.5rem;
      }

      .grid-metrics {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 1fr;
      }
    }

    .second-section {
      .container-info {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 800px;
        margin: 0 auto;
      }
    }
  }
`

export default Home
